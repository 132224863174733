import { useState } from "react";
import Home from "./Home";
import config from "./config";
import AddVideo from "./AddVideo";
import EditVideo from "./EditVideo";
import VideoListing from "./VideoListing";
import AddBulkVideo from "./AddBulkVideo";

const Dashboard = (props) => {
  const [view, setView] = useState(config.views.HOME);
  const [viewData, setViewData] = useState(null);

  const setViewWithData = (view, data = null) => {
    if (data) {
      setViewData(data);
    }

    setView(view);
  };

  const views = {};
  views[config.views.HOME] = (
    <Home setView={setViewWithData} viewData={viewData} />
  );
  views[config.views.ADD_VIDEO] = (
    <AddVideo setView={setViewWithData} viewData={viewData} />
  );
  views[config.views.ADD_BULK_VIDEO] = (
    <AddBulkVideo setView={setViewWithData} viewData={viewData} />
  );
  views[config.views.EDIT_VIDEO] = (
    <EditVideo setView={setViewWithData} viewData={viewData} />
  );
  views[config.views.VIDEO_LISTING] = (
    <VideoListing
      setView={setViewWithData}
      viewData={viewData}
      fullListing={true}
    />
  );

  return views[view];
};

export default Dashboard;
