import config from "./config";

const { useState } = require("react");
const { default: Login } = require("./Login");
const { default: Register } = require("./Register");

const Auth = (props) => {
  const [view, setView] = useState(config.views.LOGIN);

  const views = {};

  views[config.views.LOGIN] = (
    <Login setView={setView} setToken={props.setToken} />
  );
  views[config.views.REGISTER] = <Register setView={setView} />;

  return views[view];
};

export default Auth;
