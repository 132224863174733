const utils = {};

utils.shortName = (name, limit = 40) => {
  if (name.length >= limit) {
    return name.substring(0, limit - 3) + "...";
  }

  return name;
};

export default utils;
