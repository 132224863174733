import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import config from "./config";
import axios from "axios";
import utils from "./utils";

const VideoListing = (props) => {
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(0);
  const [search, setSearch] = useState("");
  const [videos, setVideos] = useState([]);
  const [videoCount, setVideoCount] = useState(0);
  const [ssQueue, setSSQueue] = useState([]);

  const [pageInput, setPageInput] = useState(0);
  const [limitInput, setLimitInput] = useState(0);

  const getVideos = async () => {
    if (!page || !limit) {
      return;
    }

    try {
      const res = await axios.get(
        `${config.api_endpoint}videos?page=${page}&limit=${limit}`,
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      );
      setVideoCount(res.data.count);
      setVideos(res.data.videos);
    } catch (error) {
      console.error(error);
      toast.error(error.message);
    }
  };

  const getSSQueue = async () => {
    try {
      const res = await axios.get(`${config.api_endpoint}ss-queue`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      setSSQueue(res.data.queue);
    } catch (error) {
      console.error(error);
      toast.error(error.message);
    }
  };

  const deleteVideo = async (id) => {
    const consent = window.confirm(
      "Are you sure you want to delete the video?"
    );
    if (!consent) {
      return;
    }

    try {
      await axios.delete(`${config.api_endpoint}video/${id}`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      getVideos();
      toast.success("Video deleted successfully");
    } catch (error) {
      console.error(error);
      toast.error("Failed to delete video");
    }
  };

  const removeDuplicates = async () => {
    try {
      const res = await axios.get(`${config.api_endpoint}remove-duplicate`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      getVideos();
      toast.success(res.data.message);
    } catch (error) {
      console.error(error);
      toast.error(error.message);
    }
  };

  const fetchScreenshot = async (id = null) => {
    try {
      const res = await axios.get(
        `${config.api_endpoint}fetch-screenshot${id ? "?id=" + id : ""}`,
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      );
      toast.success(res.data.message);
      getSSQueue();
    } catch (error) {
      console.error(error);
      toast.error(error.message);
    }
  };

  const fetchVideoDetails = async () => {
    try {
      const res = await axios.get(`${config.api_endpoint}fetch-video-details`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      getVideos();
      toast.success(res.data.message);
    } catch (error) {
      console.error(error);
      toast.error(error.message);
    }
  };

  const prevPage = () => {
    if (page === 1) {
      return;
    }

    setPage(page - 1);
  };

  const nextPage = () => {
    if (!videos.length || videos.length < limit) {
      return;
    }

    setPage(page + 1);
  };

  const apply = () => {
    setPage(pageInput);
    setLimit(limitInput);
  };

  const getRenderList = (videos) =>
    search.length
      ? videos.filter((x) =>
          x.name.toLowerCase().includes(search.toLowerCase())
        )
      : videos;

  useEffect(() => {
    setPage(1);
    setLimit(config.limits.DEFAULT_VIDEO_LISTING);
  }, []);

  useEffect(() => {
    if (props.fullListing) {
      getSSQueue();
    }
  }, [props.fullListing]);

  useEffect(() => {
    getVideos();
    setPageInput(page);
    setLimitInput(limit);
  }, [page, limit]);

  const paginationElement = (
    <div className="row pagination-filter">
      <button onClick={prevPage} disabled={page === 1}>
        Prev
      </button>
      <button
        onClick={nextPage}
        disabled={
          !videos.length || videos.length < limit || page * limit >= videoCount
        }
      >
        Next
      </button>
    </div>
  );

  const listingElement = (
    <ul className="action-list">
      {getRenderList(videos).map((video) => (
        <li key={video._id}>
          <div title={video.name}>
            {video.name ? utils.shortName(video.name, 70) : "(pending fetch)"}{" "}
          </div>
          <div className="actions">
            <span
              className="link"
              onClick={() => props.setView(config.views.EDIT_VIDEO, video)}
            >
              [Edit]
            </span>
            <span className="link" onClick={() => deleteVideo(video._id)}>
              [Delete]
            </span>
          </div>
        </li>
      ))}
    </ul>
  );

  if (props.fullListing) {
    return (
      <div>
        <nav className="crumbs">
          <ol>
            <li
              onClick={() => props.setView(config.views.HOME)}
              className="crumb link"
            >
              Home
            </li>
            <li>Videos</li>
          </ol>
        </nav>
        <div className="row pad pagination-filter">
          <span>Page: </span>
          <input
            type="number"
            value={pageInput}
            onChange={(e) => setPageInput(parseInt(e.target.value) || 1)}
            placeholder="Page"
          />
          <span>Per Page:</span>
          <input
            type="number"
            value={limitInput}
            onChange={(e) =>
              setLimitInput(
                parseInt(e.target.value) || config.limits.DEFAULT_VIDEO_LISTING
              )
            }
            placeholder="Per Page"
          />
          <span>
            {" "}
            = {(page - 1) * limit} - {page * limit} / {videoCount}
          </span>
          <button onClick={apply}>Apply</button>
        </div>
        <div className="row pad search">
          <input
            type="text"
            placeholder="Search"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>
        {listingElement}
        {paginationElement}
        <div className="row pad">
          <h3>Utilities</h3>
        </div>
        <div className="row pad">
          <button
            onClick={() => {
              props.setView(config.views.ADD_VIDEO);
            }}
          >
            Add Video
          </button>
          <button onClick={fetchVideoDetails}>Fetch All Video Details</button>
          <button onClick={removeDuplicates}>Remove All Duplicates</button>
          {/* <button onClick={() => fetchScreenshot()}>Fetch Screenshot</button> */}
        </div>
        {/* <div className="row pad">
          <h3>SS Fetch Queue</h3>
          <span className="link" onClick={getSSQueue}>
            [Refresh]
          </span>
        </div> */}
        {/* <div className="row pad">
          <ul className="action-list" style={{ width: "100%" }}>
            {!ssQueue.length && <p>No videos in Queue</p>}
            {ssQueue.map((item, index) => (
              <li key={index}>
                <div title={item.videoId.name}>
                  {utils.shortName(item.videoId.name, 70)}
                </div>
                <div className="actions">
                  {config.SS_QUEUE_VALUES[item.status]}
                </div>
              </li>
            ))}
          </ul>
        </div> */}
      </div>
    );
  } else {
    return listingElement;
  }
};

export default VideoListing;
